export let timePickerLabels = {
    es: {
        labelHours: 'Horas',
        labelMinutes: 'Minutos',
        labelSeconds: 'Segundos',
        labelIncrement: 'Incrementar',
        labelDecrement: 'Decrementar',
        labelSelected: 'Seleccionado',
        labelNoTimeSelected: 'Ninguna hora seleccionada',
        labelCloseButton: 'Cerrar'
    }
};